export const getFormattedDate = (epoch) => {
    
    const date = new Date(epoch);
    let month = (Number(date.getMonth()) + 1);
    let formattedMonth = '';
    let day = date.getDate();
    let formattedDay = '';

    if (month < 10) {
        formattedMonth = '0' + String(month)
    }

    if (day < 10) {
        formattedDay = '0' + String(day)
    }

    return formattedMonth + '/' + formattedDay + '/' + date.getFullYear();
}