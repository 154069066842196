import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import axios from "axios";
import './layout.scss';
import { useMediaQuery } from "@material-ui/core";
import ChartWidget from "../chart/chart";
import ImageWidget from "../image/image";



const LayoutView = ({ layout }) => {

  const isSmallScreen = useMediaQuery('(max-width: 1024px)');

  const getGridTemplate = (row) => {
    let value = '';
    for (let i = 0; i < row.columns.length; i++) {
      value = value + `${(row.columns[i].size) / 100}fr `
    }

    return value;
  }
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      height: `100%`,
      minWidth: "100vw",
      display: "grid",
      color: "white",
      gridRowGap: theme.spacing(1)
    },
    rows: {
      margin: 0,
      gridColumnGap: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      overflow: 'hidden',
      minWidth: 0
    },
    widget: {
      borderRadius: "10px",
      backgroundColor: 'rgba(50,50,50,0.95)',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }
  }));


  const getWidget = (widget, layout, idx) => {
    console.log(widget, idx);
    switch (widget.type.toLowerCase()) {
      case 'kw_chart':
        return <ChartWidget widgetSchema={widget} />;
      case 'kw_dashboard_gallery':
        return <ImageWidget widgetSchema={widget} layout={layout} idx={idx}/>
      default:
        return;
    }
  }

  const classes = useStyles();


  return layout && layout.rows && layout.rows.length ? <Box className={classes.root} style={{ gridTemplateRows: `repeat(${layout.rows.length}, 1fr)` }}>
    {layout.rows.map((row, rowIdx) => (
      <Box display={'grid'} key={rowIdx} className={classes.rows} style={{ gridTemplateColumns: isSmallScreen ? '1fr' : getGridTemplate(row) }}>
        {row.columns.map((column, columnIdx) => {
          return column.widgets.map((widget, widgetIdx) => {
            return getWidget(widget, layout, `${rowIdx}${columnIdx}${widgetIdx}`)
          })
        })}
      </Box>
    ))}
  </Box> : ''
};

export default LayoutView;
