import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { serviceContext } from "../../services/service-context";
import { autorun, toJS } from "mobx"
import { useSnackbar } from 'notistack';

const NotificationProxy = observer(() => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { userService, dataService, authService, appService, notificationService } = useContext(serviceContext);

    autorun(() => {
        if (notificationService.newNotification) {
            const notif = toJS(notificationService.newNotification);
            enqueueSnackbar(notif.summary, { variant: notif.severity, anchorOrigin: { horizontal: 'right', vertical: 'bottom' } });
            notificationService.newNotification = null;
        }
    })


    return '';
});


export default NotificationProxy;