import "./App.scss";
import React from "react";
import { Switch, Route, Redirect, useLocation, useHistory } from "react-router-dom";
import DashboardSelection from "./views/dashboard-selection/dashboard-selection.jsx";
import Login from "./views/auth/login/login.jsx";
import Dashboard from "./views/dashboard/dashboard.jsx";
import theme from "./theme/index";
import { ThemeProvider, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { observer } from "mobx-react-lite"
import { connect } from "react-redux";
import { SetServices } from "./redux/service/service-actions";
import { SnackbarProvider } from 'notistack';
import { serviceContext } from "./services/service-context";
import NotificationProxy from "./components/notification-proxy/notification-proxy";
import TopBar from "./components/topbar/topbar";
import { Box } from "@mui/system";


const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.palette.background.dark
  },
  spinnerBackground: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.75)',
    zIndex: 999,
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

const App = observer(({ dataService, appService, userService, authService, notificationService, chartService }) => {
  const classes = useStyles();
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingMessage, setIsSubmittingMessage] = useState(false);
  const history = useHistory();


  return <serviceContext.Provider value={{
    appService: appService,
    dataService: dataService,
    notificationService: notificationService,
    authService: authService,
    userService: userService,
    chartService: chartService
  }}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <div className={`${classes.root} App`}>
          {appService.isBusy ? <Box className={classes.spinnerBackground}>
            <img src="/images/spinner.gif" alt="spinner" />
          </Box> : ''}
          <TopBar />
          <NotificationProxy />
          <Switch>
            <Route path={"/dashboardSelection"} component={DashboardSelection}></Route>
            <Route path={"/login"} render={() => <Login />}></Route>
            <Route path={"/dashboard/:dashboardId"} component={Dashboard}></Route>
            <Route path={"/"}>
              <Redirect to="/login" />
            </Route>
          </Switch>


        </div>
      </SnackbarProvider>
    </ThemeProvider>
  </serviceContext.Provider>










});

const mapStateToProps = (state) => ({
  services: state.serviceStore.services,
});

const mapDispatchToProps = (dispatch) => ({
  setServices: (services) => dispatch(SetServices(services)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
