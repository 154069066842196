import { makeAutoObservable } from "mobx";

export class KsUserService {

    applicationId = '';
    applicationName = '';
    domainName = '';
    applicationLogo = '';
    applicationLogoDark = '';
    currentLogo = '';
    authToken = '';
    culture = '';
    permissions = '';
    region = '';
    skin = '';
    timezone = '';
    userId = '';
    userName = '';
    is_temp_password = '';
    has_password_expired = '';
    is_email_verified = '';
    is_2fa_fulfilled = '';

    constructor() {
        makeAutoObservable(this);
        const tempDomain = localStorage.getItem('signInDomain');
        const tempApplication = localStorage.getItem('signInApplication');
        this.culture = JSON.parse(localStorage.getItem('signInCulture')) || 'en-AU';

        if (tempDomain && tempApplication) {
            this.domainName = tempDomain;
            this.applicationName = tempApplication;
        }
    }

    setProperties(object) {
        for(const key in object) {
            this[key] = object[key];
        }  
    }


    resolveSignInDomainApplication = (domainApplication) => {
        const parts = domainApplication.split('.');

        if (parts.length > 1) {
            return {
                domainName: parts[0],
                applicationName: parts[1]
            }
        }
        else {
            return {
                domainName: parts[0],
                applicationName: parts[0]
            }
        }
    }


    getApplicationIdentifier = () => {
        if (this.domainName && this.applicationName) {
            if (this.domainName !== this.applicationName) {
                return this.domainName + '.' + this.applicationName;
            }
            else {
                return this.domainName;
            }
        }
        else {
            return null;
        }

    }


    resetProperties = () => {
        this.domainName = null;
        this.applicationName = null;
        this.applicationId = null;
        this.applicationLogo = null;
        this.applicationLogoDark = null;
        this.currentLogo = null;
        this.authToken = null;
        this.culture = null;
        this.permissions = null;
        this.region = null;
        this.timezone = null;
        this.userId = null;
        this.userName = null;
        this.skin = null;
    }
}
