import {createMuiTheme, colors} from '@material-ui/core';


const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#161616',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#d13f22'
    },
    secondary: {
      main: colors.grey[500]
    },
    text: {
      primary: colors.common.white,
      secondary: colors.common.white
    }
  }
});

export default theme;
