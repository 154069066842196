import { makeAutoObservable } from "mobx";

export class KsAppService {

    domain;
    mode;
    isBusy = false;

    constructor() {
        makeAutoObservable(this);
        this.domain = process.env.REACT_APP_DOMAIN;
   
        this.mode = process.env.REACT_APP_MODE;
    }
}
