import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import axios from "axios";
import { observer } from "mobx-react-lite";
import { serviceContext } from "../../services/service-context";
import { useContext } from "react";

import './image.scss';
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import CoreStyles from "react-awesome-slider/src/core/styles.scss";
import CubeAwesomeSliderStyles from "react-awesome-slider/src/styled/cube-animation/cube-animation.scss";
import FoldOutAwesomeSliderStyles from "react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss";
import FallAwesomeSliderStyles from "react-awesome-slider/src/styled/fall-animation/fall-animation.scss";
import { useState } from "react";
import { useEffect } from "react";
import { Carousel } from 'primereact/carousel';

const ImageWidget = observer(({ widgetSchema, layout, idx }) => {

  const { userService, dataService, authService, appService, notificationService, chartService } = useContext(serviceContext);
  const [images, setImages] = useState([]);

  const useStyles = makeStyles((theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      boxSizing: 'border-box',
    },
    chartBox: {
      flex: 1,
      height: '100%',
      border: `3px solid gray`,
      borderRadius: theme.spacing(1),
      boxSizing: 'border-box',
    },
    leaderBoard: {
      backgroundColor: 'black !important',
      color: 'white !important'
    }
  }
  ));

  const classes = useStyles();
  const [cursor, setCursor] = useState('inherit');
  const [timer, setTimer] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const baseItemFilter = {
    orPredicates: [{
      andPredicates: [
        {
          attributeId: 'dashboard_layout',
          operator: 'EQ',
          values: [layout.layoutId]
        },
        {
          attributeId: 'instance_identifier',
          operator: 'EQ',
          values: [widgetSchema.gallery.instanceIdentifier]
        }
      ],
    }],
  }


  const getImages = async () => {
    const res = await dataService.dataRequest("data/item/retrieveItemsFromFilter", {
      postData: {
        authToken: userService.authToken,
        tableId: "kz_dashboard_image",
        itemFilter: baseItemFilter
      },
    })

    if (res.data.status === 1) {
      setImages(res.data.data);
      console.log(images);
    }
  }


  useEffect(() => {
    console.log(layout);
    getImages();
  }, [])


  useEffect(() => {

    if (images.length) {
      const timer = setTimeout(() => {

        const nextButton = document.querySelectorAll(`.${layout.layoutId}.${widgetSchema.widgetId}.widget-${idx} .awssld>.awssld__wrapper>.awssld__controls>.awssld__next`)[0];
        console.log(images[currentIndex]);
        nextButton.click();
      }, images[currentIndex].transition_duration_seconds.value * 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [currentIndex, images]);


  return (
    <Box className={`${classes.root} imageWidget ${layout.layoutId} ${widgetSchema.widgetId} widget-${idx}`}>
      <div className={classes.chartBox}>
        {images && images.length ? <AwesomeSlider
        animation={images[currentIndex]?.exit_effect ?? 'fallAnimation'}
        cssModule={[CoreStyles, CubeAwesomeSliderStyles, FoldOutAwesomeSliderStyles, FallAwesomeSliderStyles]}
        onTransitionEnd={(e) => {
          setCurrentIndex(e.currentIndex);
        }}
        bullets={false}
        media={images.map((image, index) => {
          return {
            children: <img src={image.image}/>,
          };
        })}
      ></AwesomeSlider> : ''}


      </div>

    </Box>
  );
});





export default ImageWidget;
