import React, { useState, useContext, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Box, Button, Container, TextField, Typography, makeStyles, InputAdornment } from "@material-ui/core";
import axios from "axios";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import LockIcon from "@material-ui/icons/Lock";
import AppsIcon from "@material-ui/icons/Apps";
import { connect } from "react-redux";
import { SetServices } from "../../../redux/service/service-actions";
import { serviceContext } from "../../../services/service-context";
import { observer } from "mobx-react-lite";
import './login.scss';
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100vh",
    paddingBottom: theme.spacing(3),
    display: "grid",
    alignContent: "center",
    color: "white",
    '& a': {
      color: theme.palette.primary.main
    },
  },

  TextField: {
    "& .MuiInputBase-root": {
      backgroundColor: "#ffffff12 !important",
    },
  },
}));

const LoginView = observer(() => {
  const classes = useStyles();

  const { userService, dataService, authService, appService, notificationService } = useContext(serviceContext);

  const [showPassword, setShowPassword] = useState(false);

  const [loginCredentials, setLoginCredentials] = useState({
    applicationIdentifier: localStorage.getItem('applicationDetails') ?? '',
    emailOrUsername: localStorage.getItem('userDetails') ?? '',
    password: '',
    culture: 'en-AU'
  });




  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  }


  const handleChange = (e) => {
    setLoginCredentials({
      ...loginCredentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {

    if (userService.domainName && userService.applicationName) {
      authService.signInApplicationRequest({
        signInApplication: userService.resolveSignInDomainApplication(loginCredentials.applicationIdentifier).applicationName,
        signInDomain: userService.resolveSignInDomainApplication(loginCredentials.applicationIdentifier).domainName,
        signInNameorEmail: loginCredentials.emailOrUsername,
        signInPassword: loginCredentials.password,
        signInCulture: loginCredentials.culture,
      })
    }
    else {
      authService.signInRootRequest({
        signInApplication: userService.resolveSignInDomainApplication(loginCredentials.applicationIdentifier).applicationName,
        signInDomain: userService.resolveSignInDomainApplication(loginCredentials.applicationIdentifier).domainName,
        signInNameorEmail: loginCredentials.emailOrUsername,
        signInPassword: loginCredentials.password,
        signInCulture: loginCredentials.culture,
      })
    }


  }










  return (
    <Box className={`${classes.root} login`} display="flex" flexDirection="column" height="100%" justifyContent="center" onKeyDown={handleKeyDown}>
      {userService.authToken && userService.authToken.length ? <Redirect to="/dashboardSelection" /> : ''}
      <Container maxWidth="sm">
        <img src="/images/logo.png" alt="logo" />
        <Box mt={2} mb={3}>
          <Typography color="textPrimary" variant="h4">
            Sign in
          </Typography>

        </Box>

        <TextField
          fullWidth
          helperText={"Please enter your application details"}
          label="Application details"
          margin="normal"
          name="applicationIdentifier"
          onChange={handleChange}
          className={classes.TextField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AppsIcon />
              </InputAdornment>
            ),
          }}
          type="email"
          value={loginCredentials.applicationIdentifier}
          variant="outlined"
        />

        <TextField
          fullWidth
          helperText={"Please enter your email address or username"}
          label="Email Address or username"
          margin="normal"
          name="emailOrUsername"
          onChange={handleChange}
          className={classes.TextField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AlternateEmailIcon />
              </InputAdornment>
            ),
          }}
          type="email"
          value={loginCredentials.emailOrUsername}
          variant="outlined"
        />
        <TextField
          fullWidth
          helperText={"Please enter your password"}
          label="Password"
          margin="normal"
          name="password"
          onChange={handleChange}
          className={classes.TextField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
            endAdornment:
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>{setShowPassword(!showPassword)}}
                  edge="end"
                  sx={{color: 'white'}}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>

          }}
          type={showPassword ? 'text' : 'password'}
          value={loginCredentials.password}
          variant="outlined"
        />
        <Box my={2}>
          <Button color="primary" onClick={() => handleSubmit()} fullWidth size="large" type="submit" variant="contained" disableRipple disabled={!(loginCredentials.applicationIdentifier && loginCredentials.emailOrUsername && loginCredentials.password) || appService.isBusy}>
            Sign in
          </Button>
        </Box>

      </Container>
    </Box>
  )
});

const mapStateToProps = (state) => ({
  appService: state.serviceStore.services.appService,
  userService: state.serviceStore.services.userService,
  dataService: state.serviceStore.services.dataService,
  authService: state.serviceStore.services.authService,
  notificationService: state.serviceStore.services.notificationService,
});

const mapDispatchToProps = (dispatch) => ({
  setServices: (services) => dispatch(SetServices(services)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
