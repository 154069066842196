const INITIAL_STATE = {
    services: {
        appService: null,
        userService: null,
        authService: null,
        dataService: null,
        notificationService: null,
    }
  }
  
  // eslint-disable-next-line import/prefer-default-export
  export const serviceReducer = (state = INITIAL_STATE, action) => {
  
    switch (action.type) {
      case 'SET_SERVICES':
        return {
          ...state,
         services: {...action.payload}
        }
      default:
        return state;
    }
  }
  