import { makeAutoObservable } from "mobx";
import axios from 'axios';

export default class KsDataService {
    appService;
    userService;
    seconds = 0;
    constructor(appService, userService) {
        makeAutoObservable(this);
        this.appService = appService;
        this.userService = userService;
    }






    buildURL = (api, controller, method) => {
        const apiPorts = {
            "dev-ui": "3001",
            "dev-data": "3000",
        };

   
        let region = "";
        switch (this.appService.mode) {
            case "dev":
                return `http://localhost:${apiPorts[`dev-${api}`]}/${api}/${controller}/${method}`;
            case "tst":

                region = this.userService.region !== undefined ? this.userService.region : localStorage.getItem("signInRegion");
               
                return `https://api-${this.appService.mode}-${region || 'ap-southeast-2'}-${api}.kzen8.tech/${api}/${controller}/${method}`;
            case "prd":
                region = this.userService.region !== undefined ? this.userService.region : localStorage.getItem("signInRegion");
                return `https://api-${this.appService.mode}-${region || 'ap-southeast-2'}-${api}.kzen8.com/${api}/${controller}/${method}`;
            default:
                region = this.userService.region !== undefined ? this.userService.region : localStorage.getItem("signInRegion");
                return `https://api-${this.appService.mode}-${region || 'ap-southeast-2'}-${api}.kzen8.tech/${api}/${controller}/${method}`;
        }
    }




    uiRequest = async (method, uniqueId) => {
        try {
            const [api, controller] = ["ui", "ui"];

            const result = axios.post(this.buildURL(api, controller, method), {
                postData: {
                    authToken: this.userService.authToken,
                    uniqueId: uniqueId,
                    culture: this.userService.culture,
                    domain: this.appService.domain,
                },
            });

            return result;
        }
        catch (e) {
            return e;
        }

    }

    dataRequest = async (dataUrl, postObj) => {

        try {
            const [api, controller, method] = dataUrl.split("/");

            const result = axios.post(this.buildURL(api, controller, method), postObj);

            return result;
        }
        catch (e) {
            return e;
        }

    }

}


