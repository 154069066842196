import {combineReducers} from  'redux';
// import {persistReducer} from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

import {serviceReducer} from "./service/service-reducer";


//
// const persistConfig = {
//
// 	key:'root',
// 	storage,
// 	whitelist:['cart']
//
// }


const rootReducer = combineReducers({
	serviceStore: serviceReducer,
});


export default rootReducer;
