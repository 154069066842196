import { makeAutoObservable } from "mobx";
import { getWeek, getDate, getDaysInMonth, getHours } from 'date-fns';
import { getFormattedDate } from "../utils/ku-date-time";

export class KsChartService {


    colorCodes = [
        '#1DD141',
        '#FFF275',
        '#FF8C42',
        '#FF3C38 ',
        '#009DDC',
        '#F26430',
        '#F26430 ',
        '#3DFAFF ',
        '#52be80 ',
        '#1f094a',
        '#917abf',
        '#0bbdff',
        '#ff0b0b',

    ]

    frequencyOptions = [
        { label: "Hourly", value: "H" },
        { label: "Daily", value: "D" },
        { label: "Weekly", value: "W" },
        { label: "Monthly", value: "M" },
        { label: "Yearly", value: "Y" },
        { label: "Total", value: "T" },
    ];


    frequencyAttributeMap = {
        'H': 'statHour',
        'D': 'statDay',
        'W': 'statWeek',
        'M': 'statMonth',
        'Y': 'statYear'
    };


    constructor() {
        makeAutoObservable(this);
    }





    getOptions = (type) => {

        switch (type.toLowerCase()) {
            case 'bar':
            case 'line':
            case 'stacked':
                return {
                    responsive: true,
                    maintainAspectRatio: false,
                    // We use these empty structures as placeholders for dynamic theming.
                    scales: {
                        x:
                        {
                            ticks: {
                                color: "rgba(255,255,255,1)",
                                font: {
                                    size: 20
                                }
                            },
                            grid: { display: false, color: "rgba(255,255,255,0.1)" },
                        },

                        y:
                        {
                            ticks: {
                                color: "rgba(255,255,255,1)",
                                font: {
                                    size: 20
                                }
                            },
                            grid: { color: "rgba(255,255,255,0.1)" },
                        },

                    },
                    plugins: {
                        datalabels: {
                            display: false,
                            anchor: "end",
                            align: "end",
                        },
                        legend: {
                            display: type.toLowerCase() === 'stacked' ? true : false,
                            position: 'bottom',
                            labels: {
                                color: "rgba(255,255,255,1)",
                                font: {
                                    size: 20
                                }
                            },
                        },
                        colorschemes: {

                            scheme: 'brewer.Paired12'

                        }
                    },
                };
            case 'stackedpct':
                return {
                    responsive: true,
                    maintainAspectRatio: false,
                    // We use these empty structures as placeholders for dynamic theming.
                    scales: {
                        x:
                        {
                            ticks: {
                                color: "rgba(255,255,255,1)",
                                font: {
                                    size: 20
                                }
                            },
                            grid: { display: false, color: "rgba(255,255,255,0.1)" },
                        },

                        y:
                        {
                            ticks: {
                                max: 100,
                                color: "rgba(255,255,255,1)",
                                font: {
                                    size: 20
                                }
                            },
                            grid: { color: "rgba(255,255,255,0.1)" },
                        },

                    },
                    plugins: {
                        datalabels: {
                            display: false,
                            anchor: "end",
                            align: "end",
                        },
                        legend: {
                            display: type.toLowerCase() === 'stackedpct' ? true : false,
                            position: 'bottom',
                            labels: {
                                color: "rgba(255,255,255,1)",
                                font: {
                                    size: 20
                                }
                            },
                        },
                        colorschemes: {

                            scheme: 'brewer.Paired12'

                        }
                    },
                };
            case 'pie':
            case 'leaderboard':
                return {
                    responsive: true,
                    maintainAspectRatio: false,

                    plugins: {
                        datalabels: {
                            formatter: (value, ctx) => {
                                const label = ctx.chart.data.labels[ctx.dataIndex];
                                return label;
                            },
                        },
                        legend: {
                            position: 'top',
                            labels: {
                                color: "rgba(255,255,255,1)",
                                font: {
                                    size: 20
                                }
                            }
                        }
                    },
                };
            default:
                return;
        }



    }


    resolveChartTitle = (statGroup, selectedFrequency, displayValue, dateRangeOption, dateRangeValue, customStartDateTime, customEndDateTime, dimensionGroup, topN) => {

        let dataTypeToDisplay = displayValue.toLowerCase() === 'count' ? displayValue.toLowerCase() : statGroup['stat_value_label'] ?? 'value' ;
        let titleToReturn = `${this.frequencyOptions.find(f => f.value === selectedFrequency).label} '${dataTypeToDisplay}' of '${statGroup.display_title}'`;

        if (dateRangeOption.toUpperCase() === 'CUSTOM') {

            titleToReturn = titleToReturn + ` between ${getFormattedDate(customStartDateTime)} and ${getFormattedDate(customEndDateTime)}`;

        }
        else {
            titleToReturn = titleToReturn + ` in the last ${dateRangeValue} ${dateRangeOption.toLowerCase()}`;
        }

        if (dimensionGroup && statGroup) {
            titleToReturn = titleToReturn + ` grouped by '${statGroup[`dimension_label_${dimensionGroup}`]}'`;
        }

        if (topN) {
            titleToReturn = titleToReturn + ` (Top ${topN})`;
        }



        return titleToReturn;

    }


    getChartThemeOverrides = (selectedTheme) => {
        let overrides = {};
        if (selectedTheme === "dark-theme") {
            overrides = {
                legend: {
                    labels: { fontColor: "white" },
                },
                scales: {
                    xAxes: [
                        {
                            ticks: { fontColor: "white" },
                            gridLines: { color: "rgba(255,255,255,0.1)" },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: { fontColor: "white" },
                            gridLines: { color: "rgba(255,255,255,0.1)" },
                        },
                    ],
                },
            };
        } else {
            overrides = {};
        }

        return overrides;
    }

    resolveTransitionDateRange = (dateRangeOption, dateRangeValue, customStartDateTime, customEndDateTime) => {
        const now = Date.now();
        switch (dateRangeOption.toUpperCase()) {
            case 'HOURS':
                return {
                    startDate: now - Number(dateRangeValue) * 3600000,
                    endDate: now,
                }
            case 'DAYS':
                return {
                    endDate: now,
                    startDate: now - Number(dateRangeValue) * 24 * 3600000,
                }
            case 'WEEKS':
                return {
                    endDate: now,
                    startDate: now - Number(dateRangeValue) * 7 * 24 * 3600000,
                }
            case 'MONTHS':
                return {
                    endDate: now,
                    startDate: now - Number(dateRangeValue) * 30 * 24 * 3600000,
                }
            case 'YEARS':
                return {
                    endDate: now,
                    startDate: now - Number(dateRangeValue) * 365 * 24 * 3600000,
                }
            case 'CUSTOM':
                return {
                    endDate: Number(customStartDateTime),
                    startDate: Number(customEndDateTime),
                }
            default:
                return;

        }
    }

    resolveXaxisLabels = (res, chartInfo, startDate, endDate, selectedFrequency) => {

        const startYear = new Date(startDate).getFullYear();
        const endYear = new Date(endDate).getFullYear();
        const startMonth = new Date(startDate).getMonth() + 1;
        const endMonth = new Date(endDate).getMonth() + 1;
        const startWeek = getWeek(new Date(startDate));
        const endWeek = getWeek(new Date(endDate));
        const startDay = getDate(new Date(startDate));
        const endDay = getDate(new Date(endDate));
        const startHour = getHours(new Date(startDate));
        const endHour = getHours(new Date(endDate));


        switch (chartInfo.chartType.toLowerCase()) {
            case 'bar':
            case 'line':
            case 'stacked':
            case 'stackedpct':
                let labelsToReturn = [];
                switch (selectedFrequency) {
                    case 'Y':

                        for (let i = startYear; i <= endYear; i++) {
                            labelsToReturn.push(String(i));
                        }
                        return labelsToReturn;
                    case 'M':
                        for (let i = startYear; i <= endYear; i++) {
                            for (let j = (i === startYear ? startMonth : 1); j <= (i === endYear ? endMonth : 12); j++) {
                                const stringMonth = j < 10 ? `0${j}` : `${j}`;
                                labelsToReturn.push(String(i) + '/' + stringMonth);
                            }
                        }
                        return labelsToReturn;
                    case 'W':
                        for (let i = startYear; i <= endYear; i++) {
                            for (let j = (i === startYear ? startWeek : 1); j <= (i === endYear ? endWeek : 52); j++) {
                                const stringWeek = j < 10 ? `0${j}` : `${j}`;
                                labelsToReturn.push(String(i) + '/' + stringWeek);
                            }
                        }
                        return labelsToReturn;
                    case 'D':
                        for (let i = startYear; i <= endYear; i++) {
                            for (let j = (i === startYear ? startMonth : 1); j <= (i === endYear ? endMonth : 12); j++) {
                                for (let k = (j === startMonth ? startDay : 1); k <= (j === endMonth ? endDay : getDaysInMonth(new Date(i, j))); k++) {
                                    const stringMonth = j < 10 ? `0${j}` : `${j}`;
                                    const stringDay = k < 10 ? `0${k}` : `${k}`;
                                    labelsToReturn.push(String(i) + '/' + stringMonth + '/' + stringDay);
                                }
                            }
                        }
                        return labelsToReturn;
                    case 'H':
                        for (let i = startYear; i <= endYear; i++) {
                            for (let j = (i === startYear ? startMonth : 1); j <= (i === endYear ? endMonth : 12); j++) {
                                for (let k = (j === startMonth ? startDay : 1); k <= (j === endMonth ? endDay : getDaysInMonth(new Date(i, j))); k++) {
                                    for (let l = (k === startDay ? startHour : 0); l <= (k === endDay ? endHour : 23); l++) {
                                        const stringMonth = j < 10 ? `0${j}` : `${j}`;
                                        const stringDay = k < 10 ? `0${k}` : `${k}`;
                                        const stringHour = l < 10 ? `0${l}` : `${l}`;
                                        labelsToReturn.push(String(i) + '/' + stringMonth + '/' + stringDay + ' ' + stringHour + ':00');
                                    }
                                }
                            }
                        }
                        return labelsToReturn;
                    default:
                        res.data.forEach((data) => {
                            if (!labelsToReturn.includes(data._id[`${this.frequencyAttributeMap[selectedFrequency]}`])) {
                                labelsToReturn.push(data._id[`${this.frequencyAttributeMap[selectedFrequency]}`]);
                            }
                        });
                        return labelsToReturn;
                }
            case 'pie':
            case 'leaderboard':
                return res.data.map((data) => data.label);
            default:
                return;

        }


    }


    resolveChartType = (chartType) => {
        switch (chartType.toLowerCase()) {
            case 'bar':
            case 'stacked':
            case 'stackedpct':
                return 'bar';

            case 'pie':
                return 'pie';

            case 'leaderboard':
                return 'leaderboard';

            case 'line':
                return 'line';

            default:
                break;
        }
    }


    getFormattedGroupId = (id) => {
        const idLength = id.length;
        for (let i = 0; i < idLength; i++) {
            if (i === 2) {
                id = id.substring(0, 2) + '/' + id.substring(2, id.length);
            }
            else if (i === 4) {
                id = id.substring(0, 5) + '/' + id.substring(5, id.length);
            }
            else if (i === 6) {
                id = id.substring(0, 8) + ' ' + id.substring(8, id.length);
                id = id + ':00';
            }
        }
        id = '20' + id;

        return id;
    }

    resolveStatResponse = (res, chartInfo, selectedFrequency, startDate, endDate, valueType) => {

        const dateLabels = this.resolveXaxisLabels(res, chartInfo, startDate, endDate, selectedFrequency);
        const frequencyAttribute = this.frequencyAttributeMap[selectedFrequency];
        const response = {};

        switch (chartInfo.chartType.toLowerCase()) {
            case 'bar':
            case 'line':
                response.datasets = [{
                    data: dateLabels.map((dateLabel) => {

                        const temp = res.data.find(data => this.getFormattedGroupId(String(data._id[frequencyAttribute])) === dateLabel);
                        if (temp) {
                            return temp[valueType];
                        }
                        else {
                            return 0;
                        }

                    }), label: valueType,
                    backgroundColor: this.colorCodes[0]
                }];
                response.labels = dateLabels;
                break;
            case 'pie':
            case 'leaderboard':
                response.datasets = [{ data: res.data.map((data) => data[`${valueType}`]), label: valueType, backgroundColor: this.colorCodes }];
                response.labels = dateLabels;
                break;
            case 'stacked':
            case 'stackedpct':

                const uniqueGroupLabels = []
                const groupLabels = res.data.map((data) => data.label);

                for (const label of groupLabels) {
                    if (!uniqueGroupLabels.includes(label)) {
                        uniqueGroupLabels.push(label);
                    }
                }

                response.datasets = uniqueGroupLabels.map((label, idx) =>
                ({
                    data: dateLabels.map((dateLabel) => {

                        const temp = res.data.find(data => data.label === label && this.getFormattedGroupId(String(data._id[frequencyAttribute])) === dateLabel);
                        if (temp) {
                            return temp[valueType];
                        }
                        else {
                            return 0;
                        }

                    }),
                    label,
                    backgroundColor: this.colorCodes[idx],
                    stack: 'a'
                }));

                response.labels = dateLabels;

                break;
            default:
                break;
        }

        if (chartInfo.chartType.toLowerCase() === 'stackedpct' && response.datasets && response.datasets.length) {

            for (let x = 0; x < response.datasets[0].data.length; x++) {
                let total = 0;
                for (const dataset of response.datasets) {
                    total = total + Number(dataset.data[x]);
                }

                if (total) {
                    for (const dataset of response.datasets) {
                        dataset.data[x] = Number(((dataset.data[x] / total) * 100).toPrecision(4));
                    }
                }
            }

        }

        return response;
    }


}
