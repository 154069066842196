import React from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";

import { AppBar, Badge, Box, Hidden, IconButton, Toolbar, makeStyles } from "@material-ui/core";

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { serviceContext } from "../../services/service-context";
import { Button } from "@material-ui/core";
import './topbar.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    position: "unset",
    maxHeight: "60px"
  },
  avatar: {
    width: 60,
    height: 60,
  },
  link: {
    color: "inherit",
  },

}));

const TopBar = observer(({ }) => {
  const classes = useStyles();
  const { userService, dataService, authService, appService, notificationService } = useContext(serviceContext);
  const history = useHistory();
  const location = useLocation();

  return (
    <AppBar elevation={0} className={classes.root}>
      <Toolbar>
        <RouterLink to="/">
          {location.pathname !== '/login' ? <img className="logo" src={userService.applicationLogoDark ?? '/images/logo.png'} alt="logo" /> : ''}
        </RouterLink>
        <Box flexGrow={1} />
        <Box className="rightSection">

          {location.pathname !== '/login' ? <a class="powered-by-logo-link" href="https://kzen8.com" rel="noreferrer" target="_blank">Powered by <img alt="logo" src="/images/kzen8-logo-dark.png" /></a> : ''}

          {location.pathname === '/dashboardSelection' ? <Button variant="contained" color="primary" onClick={() => authService.signOut()}>
            Sign Out
          </Button> : ''}
          {['/dashboardSelection', '/login'].includes(location.pathname) ? '' : <IconButton color="inherit" className={classes.link} onClick={() => {
            history.goBack();
          }}>
            <ArrowBackIcon />
          </IconButton>}
        </Box>



      </Toolbar>
    </AppBar>
  );
});



export default TopBar;
