import { Button, Card, Grow, Typography, Box, makeStyles, AppBar, Badge, Hidden, IconButton, Toolbar } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import { Link as RouterLink, Redirect, useParams } from "react-router-dom";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import CoreStyles from "react-awesome-slider/src/core/styles.scss";
import CubeAwesomeSliderStyles from "react-awesome-slider/src/styled/cube-animation/cube-animation.scss";
import FoldOutAwesomeSliderStyles from "react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss";
import FallAwesomeSliderStyles from "react-awesome-slider/src/styled/fall-animation/fall-animation.scss";
import LayoutView from "../../components/layout/layout.jsx";
import { observer } from "mobx-react-lite";
import { serviceContext } from "../../services/service-context";
import { useContext } from "react";
import Tickitape from "../../components/tickitape/tickitape";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "grid",
    color: "white",
    minWidth: "100vw",
    marginLeft: "auto",
    marginRight: "auto",
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
  footer: {
    height: '60px',
    width: '100vw',
    position: 'fixed',
    bottom: 0,
    color: theme.palette.text.primary,
    backgroundColor: '#161616',
    fontWeight: 'bold',
    fontSize: '33px',
    display: 'grid',
    alignItems: 'center',
  }
}));

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Dashboard = observer(() => {
  const classes = useStyles();
  const [cursor, setCursor] = useState('inherit');
  const [timer, setTimer] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { dashboardId } = useParams();
  const [dashboardTemplate, setDashboardTemplate] = useState(null);
  const [dashboardTransitions, setDashboardTransitions] = useState([]);
  const [dashboardLayouts, setDashboardLayouts] = useState([]);
  const { userService, dataService, authService, appService, notificationService } = useContext(serviceContext);


  const getDashboardTemplate = async () => {
    const dashboardData = await dataService.uiRequest("retrieveDashboardTemplate", dashboardId);
    const { data, status } = dashboardData.data;

    if (status === 1 && data) {
      setDashboardTemplate(data);
    }
  }

  useEffect(() => {
    getDashboardTemplate();

    return () => {
      clearTimeout(timer);
    }
  }, [])

  useEffect(() => {
    if (dashboardTemplate) {
      setDashboardTransitions(dashboardTemplate.dashboardTransitions)
    }
  }, [dashboardTemplate]);


  const getDashboardLayouts = async () => {
    const newArray = [];
    for (const transition of dashboardTransitions) {
      const layoutData = await dataService.uiRequest("retrieveLayout", transition.dashboardLayoutId);
      const { data, status } = layoutData.data;

      if (status === 1 && data) {
        newArray.push(data);
      }
    }
    setDashboardLayouts(newArray);
  };


  useEffect(() => {
    getDashboardLayouts();
  }, [dashboardTransitions])

  useEffect(() => {

    if (dashboardTransitions && dashboardTransitions.length && dashboardTransitions.length > 1 && dashboardLayouts && dashboardLayouts.length) {

      const timer = setTimeout(() => {


        const nextButton = document.querySelectorAll(".dashboard>.awssld>.awssld__wrapper>.awssld__controls>.awssld__next")[0];
          nextButton.click();
      }, dashboardTransitions[currentIndex].transitionDurationSeconds * 1000);

      return () => {
        clearTimeout(timer);
      };

    }

  }, [currentIndex, dashboardTransitions, dashboardLayouts]);


  const trackMouse = (e) => {
    clearTimeout(timer);

    if (cursor !== 'inherit') {
      setCursor('inherit');
    }


    setTimer(setTimeout(() => {
      setCursor('none');
    }, 5000));
  }

  return (
    <div className={`${classes.root} dashboard`} onMouseMove={trackMouse} style={{ cursor: cursor }}>
      {!userService.authToken ? <Redirect to="/login" /> : ''}
      {dashboardTransitions && dashboardTransitions.length && dashboardLayouts && dashboardLayouts.length ? <AwesomeSlider
        animation={dashboardTransitions[currentIndex].exitEffect}
        cssModule={[CoreStyles, CubeAwesomeSliderStyles, FoldOutAwesomeSliderStyles, FallAwesomeSliderStyles]}
        onTransitionEnd={(e) => {
          setCurrentIndex(e.currentIndex);
        }}
        bullets={false}
        media={dashboardLayouts.map((layout, index) => {
          return {
            children: <LayoutView layout={layout} key={index} />,
          };
        })}
      ></AwesomeSlider> : ''}
      <Box className={classes.footer}>
        <Tickitape />
      </Box>

    </div>
  );
});

export default Dashboard;
