import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import axios from "axios";
import { observer } from "mobx-react-lite";
import { serviceContext } from "../../services/service-context";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Bar, Pie, Line } from 'react-chartjs-2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './chart.scss';


const ChartWidget = observer(({ widgetSchema }) => {
  const [statGroup, setStatGroup] = useState({});
  const [chartOptions, setChartOptions] = useState(null);
  const [chartType, setChartType] = useState(null);
  const [content, setContent] = useState(null);
  const [chartData, setChartData] = useState({});
  const [chartTransitions, setChartTransitions] = useState([]);
  const [currentTransition, setCurrentTransition] = useState(0);
  const [timer, setTimer] = useState(null);
  const [chartTitle, setChartTitle] = useState(null);
  const { userService, dataService, authService, appService, notificationService, chartService } = useContext(serviceContext);

  const useStyles = makeStyles((theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
    },
    chartBox: {
      flex: 1,
      border: `3px solid gray`,
      borderRadius: theme.spacing(1),
      boxSizing: 'border-box',
    },
    leaderBoard: {
      backgroundColor: 'black !important',
      color: 'white !important'
    }
  }
  ));

  const classes = useStyles();

  useEffect(() => {

 
    setupStatGroup();
    setChartType(chartService.resolveChartType(widgetSchema.chart.chartType));
    setChartOptions(chartService.getOptions(widgetSchema.chart.chartType));

    if (widgetSchema.chart.chartTransitions) {
      setChartTransitions(widgetSchema.chart.chartTransitions);
    }


    return () => {
      clearTimeout(timer);
    }

  }, []);

  useEffect(() => {
    if (chartTitle && chartTitle.length) {
      setChartOptions({
        ...chartOptions,
        plugins: {
          ...chartOptions.plugins,
          title: {
            display: true,
            text: chartTitle,
            wrap: true,
            color: 'white',
            font: {
              size: 20
            }
          }
        }
      })
    }
  }, [chartTitle]);

  useEffect(() => {
    if (chartOptions && chartData && chartOptions.plugins.title && chartTitle === chartOptions.plugins.title.text) {

      switch (chartType.toLowerCase()) {
        case 'pie':

          setContent(<Pie data={chartData} options={chartOptions} />);
          break;
        case 'leaderboard':
          setContent(<TableContainer component={Paper} className={'leaderBoard'}>
            <Typography variant="h6">{chartTitle}</Typography>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{chartData.groupByDimensionLabel}</TableCell>
                  <TableCell align="right">{chartData.datasets[0].label.toLowerCase() === 'value' ? statGroup['stat_value_label'] ?? 'value' :
                    chartData.datasets[0].label}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {chartData.datasets[0].data.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {chartData.labels[index] ?? 'null'}
                    </TableCell>
                    <TableCell align="right">{row}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>);
          break;
        case 'bar':
          setContent(<Bar data={chartData} options={chartOptions} />);
          break;
        case 'line':
          setContent(<Line data={chartData} options={chartOptions} />);
          break;
        default:
          break;

      }
    }
  }, [chartOptions, chartData]);

  useEffect(() => {
    (async () => {
      if (chartTransitions && chartTransitions.length && statGroup) {
        await getTransitionData(chartTransitions[currentTransition]);

        if (chartTransitions.length > 1) {
          setTimer(setTimeout(() => {
            if (currentTransition < chartTransitions.length - 1) {
              setCurrentTransition(currentTransition + 1);
            }
            else {
              setCurrentTransition(0);
            }
          }, Number(chartTransitions[currentTransition].transitionDurationSeconds) * 1000));
        }
      }
    })()
  }, [statGroup, currentTransition]);



  const getTransitionData = async (transition) => {

    const { startDate, endDate } = chartService.resolveTransitionDateRange(transition.dateRangeOption, transition.dateRangeValue, transition.customStartDateTime, transition.customEndDateTime);
    const transitionData = await dataService.dataRequest("data/stat/getStats", {
      postData: {
        authToken: userService.authToken,
        filter: {
          startDate: startDate,
          endDate: endDate,
          key: widgetSchema.chart.statGroupId,
        },
        frequency: transition.frequency || 'T',
        dimensionGroups: transition.groupByDimension || transition.groupByDimension === 0 ? [transition.groupByDimension.toString()] : null,
        topN: transition.topN ? {
          value: transition.topN,
          valueType: transition.displayValue.toLowerCase(),
        } : null,
      },
    });


    if (transitionData.data.status === 1) {
      let chartResolved = chartService.resolveStatResponse(transitionData.data, widgetSchema.chart, transition.frequency || 'T', startDate, endDate, transition.displayValue.toLowerCase());
      let newTitle; 
      
      if(transition.displayTitle){
        newTitle = transition.displayTitle;
      }
      else
      {
        newTitle = chartService.resolveChartTitle(statGroup, transition.frequency || 'T', transition.displayValue.toLowerCase(), transition.dateRangeOption, transition.dateRangeValue, transition.customStartDateTime, transition.customEndDateTime, transition.groupByDimension || null, transition.topN || null);
      }
      
      chartResolved.groupByDimensionLabel = statGroup[`dimension_label_${transition.groupByDimension}`];
      setChartData(chartResolved);
      setChartTitle(newTitle);


    }

  }


  const setupStatGroup = async () => {
    const res = await dataService.dataRequest("data/item/retrieveItemFromId", {
      postData: {
        authToken: userService.authToken,
        tableId: "kz_stat_group",
        itemId: widgetSchema.chart.statGroupId,
      },
    })

    if (res.data.status === 1) {
      setStatGroup(res.data.data[0][Object.keys(res.data.data[0])[0]]);
    }
  }







  return (
    <Box className={classes.root}>
      <div className={classes.chartBox}>
        {content}


      </div>

    </Box>
  );
});

export default ChartWidget;
