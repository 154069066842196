import { Button, Card, Grow, Typography, Box, makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard-selection.scss";
import { Link, Redirect } from "react-router-dom";
import TopBar from '../../components/topbar/topbar.jsx'
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { serviceContext } from "../../services/service-context";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "grid",
    alignContent: "start",
    color: "white",
    paddingTop: '70px',

    "& a": {
      textDecoration: "none",
    },
  },
}));

const DashboardSelection = observer(({ }) => {
  const classes = useStyles();
  const [dashboardSelections, setDashboardSelections] = useState([]);
  const { userService, dataService, authService, appService, notificationService } = useContext(serviceContext);

  const getDashboards = async () => {
    const dashboardData = await dataService.dataRequest("data/item/retrieveItemsFromFilter", {
      postData: {
        authToken: userService.authToken,
        tableId: 'kz_dashboard',
        culture: userService.culture,
        itemFilter: {}
      }
    });
    const { data, status } = dashboardData.data;

    if (status === 1 && data && data.length) {
      setDashboardSelections(data);
    }

  };

  useEffect(() => {
    getDashboards();
  }, [])

  return (

    <div className={`${classes.root} dashboard-selection`}>
      {!userService.authToken ? <Redirect to="/login" /> : ''}
      <Typography variant="h4" align="center" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        Choose a dashboard <EqualizerIcon style={{ marginLeft: '10px', fontSize: '32px' }} />
      </Typography>
      <Box className="dashboard-selection-container">
        {dashboardSelections.map((dashboardSelection, idx) => {
          return (
            <Grow in={true} key={idx} style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 500 * idx } : {})}>
              <Link to={`/dashboard/${dashboardSelection._id}`}>
                <span className="dashboard-selector" variant="contained" data-tilt>
                  <Typography variant="h5">{dashboardSelection.title}</Typography>
                </span>
              </Link>
            </Grow>
          );
        })}
      </Box>
    </div>

  );
});

export default DashboardSelection;
