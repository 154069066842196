import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals";
import KsDataService from "./services/ks-data-service";
import { KsUserService } from "./services/ks-user-service";
import { KsAppService } from "./services/ks-app-service";
import { KsAuthService } from "./services/ks-auth-service";
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { KsNotificationService } from "./services/ks-notification-service";
import { KsChartService } from "./services/ks-chart-service";

const userService = new KsUserService();
const appService = new KsAppService();
const chartService = new KsChartService();
const notificationService = new KsNotificationService();
const dataService = new KsDataService(appService, userService);
const authService = new KsAuthService(dataService, userService, notificationService, appService);


ReactDOM.render(
  (<Provider store={store}>
    <BrowserRouter>
      <App dataService={dataService} appService={appService} userService={userService} authService={authService} notificationService={notificationService} chartService={chartService}/>
    </BrowserRouter>
  </Provider>),
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
