 import {createStore, applyMiddleware} from 'redux';
import logger from 'redux-logger';
import rootReducer from './root-reducer';
import {persistStore, persistReducer} from 'redux-persist';

import storage from 'redux-persist/lib/storage'








const middlewares = [ logger];


export const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['currentStore', 'currentCustomer']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, applyMiddleware(...middlewares));


export const persistor = persistStore(store);


