import axios from 'axios';
import { makeAutoObservable } from 'mobx';

export class KsAuthService {

    dataService;
    userService;
    notificationService;
    appService;

    constructor(dataService, userService, notificationService, appService) {
        makeAutoObservable(this);
        this.dataService = dataService;
        this.userService = userService;
        this.notificationService = notificationService;
        this.appService = appService;
    }

    signInRootRequest = async (signInData) => {

        try {
            this.appService.isBusy = true;
            const result = await axios.post(this.dataService.buildURL("ui", "auth", "signInNexusRoot"), {
                postData: {
                    signInApplication: signInData.signInApplication,
                    signInDomain: signInData.signInDomain,
                    signInNameorEmail: signInData.signInNameorEmail,
                    signInPassword: signInData.signInPassword,
                    signInCulture: signInData.signInCulture,
                },
            });

            if (result.data.status === 1) {
                this.notificationService.addNotification(result.data.status, 'Logged in successfully');
            }
            else {
                this.notificationService.addNotification(result.data.status, result.data.message);
            }

            this.appService.isBusy = false;


            const { data, status } = result.data;

            if (status === 1) {

                localStorage.setItem('applicationDetails', data.domainName + '.' + data.applicationName);
                localStorage.setItem('userDetails', this.getUsername(data));


                this.userService.setProperties({
                    authToken: data.authToken,
                    userId: data.userId,
                    culture: data.culture,
                    region: data.region,
                    applicationLogoDark: data.applicationLogos.dark
                })
            }
        }
        catch (err) {
            return err;
        }

    }


    getUsername = (data) => {
        if (data.isKzen8or) {
            return data.userName.substring(9, data.userName.length - 1);
        }
        return data.userName;
    }

    signInApplicationRequest = async (signInData) => {
        try {
            this.appService.isBusy = true;

            const result = await axios.post(this.dataService.buildURL("ui", "auth", "signInNexusApplication"), {
                postData: {
                    signInApplication: signInData.signInApplication,
                    signInDomain: signInData.signInDomain,
                    signInNameorEmail: signInData.signInNameorEmail,
                    signInPassword: signInData.signInPassword,
                    signInCulture: signInData.signInCulture,
                },
            });

            this.appService.isBusy = false;


            return result;
        }
        catch (err) {
            return err;
        }

    }


    signOut = () => {
        this.userService.resetProperties();
    }



}



