import { makeAutoObservable } from "mobx";

export class KsNotificationService {

   newNotification = null;

    constructor() {
        makeAutoObservable(this);
    }


    addNotification(status, message, errors = [], isSticky = false) {

        switch (status) {
            case 0:
              this.newNotification = ({ severity: "error", closable: isSticky, summary: message, detail: this.buildErrorList(errors), sticky: isSticky });
              break;
            case 1:
              this.newNotification = ({ severity: "success", closable: isSticky, summary: message, detail: this.buildErrorList(errors), sticky: isSticky});
              break;
            case 2:
              this.newNotification = ({ severity: "warning", closable: isSticky, summary: message, detail: this.buildErrorList(errors), sticky: isSticky });
              break;
            case 3:
              this.newNotification = ({ severity: "warning", closable: isSticky, summary: message, detail: this.buildErrorList(errors), sticky: isSticky });
              break;
            case 4:
              this.newNotification = ({ severity: "warning", closable: isSticky, summary: message, detail: this.buildErrorList(errors), sticky: isSticky });
              break;
            case 5: //info status for popups
              this.newNotification = ({ severity: "info", closable: isSticky, summary: message, detail: this.buildErrorList(errors), sticky: isSticky });
              break;
            default:
              this.newNotification = ({ severity: "error", closable: isSticky, summary: message, detail: this.buildErrorList(errors), sticky: isSticky });
          }
    
        }

        clearNotifications() {
            this.newNotification = null;
        }

        buildErrorList(errors) {
            if (errors.length > 0) {
              let errorList = `<ul>`;
              for (const error of errors) {
                errorList += `<li>${error}</li>`;
              }
              errorList += `</ul>`;
        
              return errorList;
            }
            return "";
          }
}
