import React, { useContext, useEffect, useState } from "react";
import Ticker from 'react-ticker'
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { serviceContext } from "../../services/service-context";
import { Link as RouterLink, Redirect, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    position: 'absolute',
    bottom: 0,
  },
  avatar: {
    width: 60,
    height: 60,
  },
  link: {
    color: "inherit",
  },

}));

const Tickitape = observer(() => {
  const [breakingNews, setBreakingNews] = useState(undefined);
  const { dashboardId } = useParams();

  useEffect(() => {

    getBreakingNewsFromAPI();

    const timer = setInterval(() => {
      getBreakingNewsFromAPI();
    }, 3000);

    return () => {
      clearInterval(timer);
    }


  }, []);

  const { userService, dataService, authService, appService, notificationService, chartService } = useContext(serviceContext);

  const getBreakingNewsFromAPI = async () => {

    const messageData = await dataService.dataRequest("data/item/retrieveItemsFromFilter", {
      postData: {
        authToken: userService.authToken,
        tableId: "kz_dashboard_message",
        itemFilter: {
          orPredicates: [
            {
              andPredicates: [
                {
                  attributeId: 'dashboard',
                  operator: 'NULL',
                  values: []
                },
                {
                  attributeId: 'kz_deleted',
                  operator: 'NEQ',
                  values: ['true']
                }
              ]
            },
            {
              andPredicates: [
                {
                  attributeId: 'dashboard',
                  operator: 'EQ',
                  values: [dashboardId]
                },
                {
                  attributeId: 'kz_deleted',
                  operator: 'NEQ',
                  values: ['true']
                }
              ]
            }
          ]
        },
      },
    });



    if (messageData.data.status === 1 && messageData.data.data.length) {
      const dataToShow = messageData.data.data;

      dataToShow.sort(function (a, b) {
        return a.sort_index.value - b.sort_index.value;
      })



      addToTicker(<span>{
        dataToShow.map((message) => {
          return <span style={message.colour ? {color: message.colour } : {}}>  {message.message}            </span>
        })}
      </span>);
    }

  };

  const addToTicker = async (newNews) => {

    setBreakingNews(newNews);

  };






  return (
    <>
      <Ticker offset="run-in" speed={10}>
        {() => (breakingNews ? (
          <p style={{ whiteSpace: "pre", margin: "0 50px", color: "yellow" }}>{breakingNews}</p>
        ) : (
          <p style={{ visibility: "hidden", color: "yellow" }}>Placeholder</p>
        ))}
      </Ticker>
    </>

  );
});



export default Tickitape;
